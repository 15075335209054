import { useContext, mergeProps, splitProps } from 'solid-js';
import { IconCaretDownMd } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import { useFocusable } from '../providers/focusable';
import { InputContext } from './field';
import { FormContext, disableAutocomplete } from './form';
import type { JSX } from 'solid-js';

type Props = Omit<JSX.SelectHTMLAttributes<HTMLSelectElement>, 'aria-required'> & {
	prefixElement?: JSX.Element;
	suffixElement?: JSX.Element;
};

export function Select(inputProps: Props) {
	const { data } = useContext(FormContext);
	const [context] = useContext(InputContext);
	const focusable = useFocusable<Props>();
	const merged = mergeProps(context, focusable, inputProps);
	const [custom, props] = splitProps(merged, ['children', 'prefixElement', 'suffixElement']);

	return (
		<div class="group relative flex items-center">
			<div class="pointer-events-none absolute inset-y-2 left-2 flex flex-row items-center">{custom.prefixElement}</div>
			<select
				{...props}
				disabled={data.pending || props.readonly || props.disabled}
				aria-required={props.required}
				{...disableAutocomplete(props)}
				class={twMerge(
					'w-full appearance-none truncate rounded-xl border border-solid border-neutral bg-white px-4 py-3 pe-6 outline-none transition-all duration-200 focus-visible:ring-1 focus-visible:ring-brand-700 aria-invalid:border-red-700',
					props.class,
				)}
			>
				{custom.children}
			</select>
			<div class="pointer-events-none absolute right-2">
				{custom.suffixElement ?? <IconCaretDownMd class="size-6" />}
			</div>
		</div>
	);
}

type OptionProps = JSX.OptionHTMLAttributes<HTMLOptionElement>;

export function Option(props: OptionProps) {
	return <option {...props} />;
}
